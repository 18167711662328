/*
 * Archive of our homepage from February - April 2020.
 */

import React from "react"
import Helmet from "react-helmet"
import EmailField from "../components/EmailField";
import DefaultPageLayout from "../components/DefaultPageLayout";

const IndexPage = () => {
  return (
    <DefaultPageLayout>
      <div className='IndexPage'>
        <Helmet title="the Browser Company of New York">
          <meta property='og:image' content='http://thebrowser.company/social.png' />
          <meta property='twitter:card' content='summary_large_image' />
          <meta property='twitter:creator' content='@thebrowsercompany' />
          <meta property='twitter:title' content='the Browser Company of New York' />
          <meta property='twitter:image' content='http://thebrowser.company/social.png' />
        </Helmet>
        <header>
            <EmailField />
            <a href='mailto:hello@thebrowser.company'>Say Hello!</a>
        </header>
        <div id='logo' aria-label="the Browser Company of New York"></div>
        <footer>
          <EmailField />
        </footer>
      </div>
    </DefaultPageLayout>
  )
}

export default IndexPage
