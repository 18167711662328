import React, { useState, useEffect, useRef } from "react"

import mail_icon from '../images/mail_icon.svg';
import './EmailField.css';

const STATE_NONE = 'none';
const STATE_FORM = 'form';
const STATE_SUBMITTING = 'submitting';
const STATE_CONFIRMED = 'confirmed';

const EmailField = ({}) => {
  const [state, setState] = useState(STATE_NONE);
  const inputRef = useRef();
  useEffect(() => {
    if (state === STATE_FORM) {
      inputRef.current.focus();
    }
  }, [state]);

  const showConfirmationState = () => {
    setState(STATE_CONFIRMED);
    setTimeout(() => {
      setState((prevState) => {
        // reset state to 'none' if the state is still 'confirmed'
        return prevState === STATE_CONFIRMED ? STATE_NONE : prevState;
      })
    }, 1500);
  };

  const onSubmit = (ev) => {
    setState(STATE_SUBMITTING);
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        showConfirmationState();
        form.reset();
      } else {
        // error?
        setState(STATE_FORM);
      }
    };
    xhr.send(data);
  }

  const formDisabled = (state !== STATE_FORM) ? 'disabled' : false;

  return (
    <div className={`EmailField ${state}`}>
      <div className='button' onClick={() => setState(STATE_FORM)}>
        <label>{ state === STATE_CONFIRMED ? "Thanks! We'll keep you posted :)" : "Sign up for updates" }</label>
      </div>
      <form onSubmit={onSubmit} action="https://formspree.io/mzbrazan" method="POST">
          <img className='icon' src={mail_icon} />
          <input disabled={formDisabled} type='email' name='email' placeholder='email@domain.xyz' ref={inputRef} />
          <input disabled={formDisabled} type='submit' />
        </form>
    </div>
  )
}
export default EmailField;
